import React, { Fragment } from 'react';
import { Alert } from 'reactstrap';
import Button from 'gdc-react-components-atoms-button';
import useApi from './../../hooks/useApi'

const LogoutSuccess = ({ signOutIFrameUrl }) =>
    <Fragment>
        <p class="text-muted mb-5">You have successfully signed out of the application and can close the browser window.</p>
        <iframe width="0" height="0" className="d-none" src={signOutIFrameUrl}></iframe>
    </Fragment>

const Confirm = ({ isLoading, onConfirmClick }) =>
    <Fragment>
        <p class="text-muted">Are you sure you wish to sign out?</p>
        <div className="d-flex mb-5">
            <Button id="submit" extraClass="primary slim full mt-5" onClick={onConfirmClick}>{isLoading ? 'Loading...' : 'Yes'}</Button>
        </div>
        <p class="text-muted">To stay signed in and continue to use our services, please close this screen or use the back button on your browser to return to your application.</p>
    </Fragment>

const Logout = ({ }) => {

    const [{ isLoading, error, response }, callApi] = useApi();

    const handleConfirmClick = (event) => {
        event.preventDefault();
        callApi({
            method: 'get',
            url: 'api/Authenticate/Logout',
            data: {}
        });
    }

    if (response && response.postLogoutRedirectUri)
        window.location = response.postLogoutRedirectUri;

    return (
        <Fragment>
            <h1 className=''>Sign Out</h1>
            {error && <Alert color="danger">{error}</Alert>}
            {!response && <Confirm isLoading={isLoading} onConfirmClick={handleConfirmClick} />}
            {response && <LogoutSuccess {...response} />}
        </Fragment>
    )
}

export default Logout
