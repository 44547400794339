import React from 'react';
import ScopeItem from './ScopeItem';

const ScopeList = ({ section, title, scopes, onItemChange }) =>
    <div>
        <h3>{title}</h3>
        {scopes && scopes.map(scope => <ScopeItem
            key={scope.name}
            section={section}
            onChange={onItemChange}
            {...scope} />)}
    </div>

export default ScopeList