import React, { Fragment, useState } from 'react';
import Button from 'gdc-react-components-atoms-button';
import LabelledCheckbox from './../LabelledCheckbox';
import useApi from './../../hooks/useApi'
import { Alert } from 'reactstrap';
import ScopeList from './ScopeList';

const Form = ({ onSubmit, ...rest }) => {

    const [formState, setFormState] = useState({ ...rest })

    const handleScopeClick = (name, section) => {
        const updated = formState[section].map(scope => ({
            ...scope,
            checked: scope.name === name ? !scope.checked : scope.checked
        }))

        setFormState({ ...formState, [section]: updated })
    }

    const handleRememberConsentOnChange = () => {
        setFormState({
            ...formState,
            rememberConsent: !formState.rememberConsent
        })
    }

    const [{ isLoading, error, response }, callApi] = useApi();

    const handleCancelClick = (event) => {
        event.preventDefault()
        window.location = "account/logout";
    }

    const handeSubmit = (event) => {

        event.preventDefault()

        const isChecked = scope => scope.checked
        const getName = scope => scope.name

        const consented = formState.identityScopes
            .filter(isChecked)
            .map(getName)
            .concat(
                formState.resourceScopes
                    .filter(isChecked)
                    .map(getName)
            )

        callApi({
            method: 'post',
            url: 'api/Consent/SendConsent',
            data: {
                button: "yes",
                scopesConsented: consented,
                rememberConsent: formState.rememberConsent,
                returnUrl: formState.returnUrl
            }
        });
    }

    if (response && response.redirectUrl) {
        window.location = response.redirectUrl;
    }

    const { identityScopes, resourceScopes, rememberConsent, allowRememberConsent } = formState

    return (
        <Fragment>
            {error && <Alert color="danger">Access Denied</Alert>}
            {response && <Alert color="success">Success</Alert>}

            <form onSubmit={handeSubmit}>
                <hr className="separator" />
                <ScopeList title="Personal Information" section="identityScopes" scopes={identityScopes} onItemChange={handleScopeClick} />
                <div className="d-flex">
                    <Button id="submit" type="submit" extraClass="primary slim full mt-5" onClick={handeSubmit} disabled={isLoading}>{isLoading ? 'Loading...' : 'Allow'}</Button>
                    <Button id="cancel" extraClass="secondary slim full mt-5" onClick={handleCancelClick}>Don't Allow</Button>
                </div>
                <div className="d-flex pt-3">
                    {allowRememberConsent &&
                        <LabelledCheckbox name="rememberDecision" checked={rememberConsent} onChange={handleRememberConsentOnChange} >
                            Remember My Decision.
                    </LabelledCheckbox>
                    }
                </div>
            </form>
        </Fragment>
    )
}

export default Form
