import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { Alert } from 'reactstrap';
import InputText from 'gdc-react-components-atoms-input-text';
import Button from 'gdc-react-components-atoms-button';
import Checkbox from 'gdc-react-components-atoms-checkbox';
import useApi from './../../hooks/useApi'
import queryString from 'query-string'

const Home = ({ location }) => {

    const query = queryString.parse(location.search)

    const [formState, setFormState] = useState({ username: '', password: '', rememberMe: false, returnUrl: query.ReturnUrl || "/" });
    const [{ isLoading, error, response }, callApi] = useApi();

    const handleSubmit = (event) => {
        event.preventDefault();
        callApi({
            method: 'post',
            url: 'api/Authenticate',
            data: formState
        });
    }

    const handleUsernameOnChange = value => {
        setFormState({ ...formState, username: value })
    }

    const handlePasswordOnChange = value => {
        setFormState({ ...formState, password: value })
    }

    const handleRememberMeOnChange = () => {
        setFormState({ ...formState, rememberMe: !formState.rememberMe })
    }

    const isValid = formState.username && formState.password

    if (response && response.redirectUrl) {
        window.location = response.redirectUrl;
    }

    return (
        <Fragment>
            <h1 className='mb-5'>Sign In.</h1>
            {response && response.isOk && <Alert color="success">Sign In Successful {response.redirectUrl && " - Redirecting..."}</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <InputText id="username" name="username" type='text' placeholder="Username" variant="full" value={formState.username} onChange={handleUsernameOnChange} />
                </div>
                <div className="form-group">
                    <InputText id="password" name="password" type="password" placeholder="Password" variant="full" value={formState.password} onChange={handlePasswordOnChange} />
                </div>
                <div className="_form-check">
                    <Checkbox id="rememberMe" name="rememberMe" checked={formState.rememberMe} onChange={handleRememberMeOnChange} />
                    <label className="form-check-label text-muted ml-3" htmlFor="rememberMe">
                        Remember Me?
                    </label>
                </div>
                <div className='mb-4'>
                    <Button id="submit" type="submit" extraClass="primary slim full mt-5" disabled={!isValid || isLoading}>{isLoading ? 'Loading...' : 'Sign In'}</Button>
                </div>
                <small><Link to='/forgotpassword'>Forgot Password</Link></small>
            </form>
        </Fragment>
    )
}

export default withRouter(Home)