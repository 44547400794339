import React, { Fragment, useState } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Alert } from 'reactstrap';
import InputText from 'gdc-react-components-atoms-input-text';
import Button from 'gdc-react-components-atoms-button';
import useApi from './../../hooks/useApi'

const Success = ({ }) =>
    <Alert color="success">Password Reset Successful<br />Please check your email and return to <Link to="/">Sign In</Link></Alert>

const Form = ({ username, isValid, isLoading, onUsernameChange, onSubmit, onCancel }) =>
    <form onSubmit={onSubmit}>
        <div className="form-group">
            <InputText id="username" name="username" type="text" placeholder="Username" variant="full" value={username} onChange={onUsernameChange} />
        </div>
        <div className="d-flex">
            <Button id="submit" type="submit" extraClass="primary slim full mt-5" disabled={!isValid}>{isLoading ? 'Loading...' : 'Submit'}</Button>
            <Button id="cancel" extraClass="secondary slim full mt-5" onClick={onCancel}>Cancel</Button>
        </div>
    </form>

const ForgottenPassword = ({ history }) => {

    const [formState, setFormState] = useState({ username: '' });

    const [{ isLoading, error, response }, callApi] = useApi();

    const handleSubmit = (event) => {
        event.preventDefault();
        callApi({
            method: 'post',
            url: 'api/Authenticate/ForgotPassword',
            data: formState
        });
    }

    const handleUsernameOnChange = value => {
        setFormState({ ...formState, username: value })
    }

    // Why use history? Because the GDC Button the CSS doesn't play ball when wrapped in a react-router <Link>
    const handleCancelClick = () => history.push("/");

    const isValid = formState.username

    return (
        <Fragment>
            <h1 className='mb-5'>Forgot Password?</h1>
            {error && <Alert color="danger">{error.Username && error.Username.length > 0 ? error.Username[0] : error}</Alert>}
            {response && <Success />}
            {!response && <Form
                isValid={isValid}
                isLoading={isLoading}
                onUsernameChange={handleUsernameOnChange}
                onSubmit={handleSubmit}
                onCancel={handleCancelClick}
                {...formState}
                />}

        </Fragment>
    )
}

export default withRouter(ForgottenPassword)
