import React, { Fragment, useState } from 'react';
import { Alert } from 'reactstrap';
import InputText from 'gdc-react-components-atoms-input-text';
import Button from 'gdc-react-components-atoms-button';
import useApi from './../../hooks/useApi'
import queryString from 'query-string'

const ResetPassword = ({ location }) => {

    const query = queryString.parse(location.search)

    const [formState, setFormState] = useState({ username: '', password: '', confirmPassword: '', resetToken: query.token || '', returnUrl: query.returnUrl || "/" });
    const [{ isLoading, error, response }, callApi] = useApi();

    const handleSubmit = (event) => {
        event.preventDefault();
        callApi({
            method: 'post',
            url: 'api/Authenticate/ResetPassword',
            data: formState
        });

        setFormState({ ...formState, password: '', confirmPassword: '' })
    }

    const handleUsernameOnChange = value => {
        setFormState({ ...formState, username: value })
    }

    const handleNewPasswordOnChange = value => {
        setFormState({ ...formState, password: value })
    }

    const handleConfirmPasswordOnChange = value => {
        setFormState({ ...formState, confirmPassword: value })
    }

    const isValid = formState.username && formState.password && formState.confirmPassword

    const newPasswordMatch = formState.password === formState.confirmPassword

    if (response && response.redirectUrl) {
        window.location = response.redirectUrl;
    }

    return (
        <Fragment>
            {false && <pre>{JSON.stringify(formState, null, 2)}</pre>}
            <h1 className='mb-5'>Reset Password</h1>
            {response && response.isOk && <Alert color="success">Reset Password Successful</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
            {!newPasswordMatch && <Alert color="danger">New password and confirm password do not match</Alert>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <InputText id="username" name="username" type='text' placeholder="Username" variant="full" value={formState.username} onChange={handleUsernameOnChange} />
                </div>
                <div className="form-group">
                    <InputText id="newPassword" name="newPassword" type="password" placeholder="New Password" variant="full" value={formState.password} onChange={handleNewPasswordOnChange} />
                </div>
                <div className="form-group">
                    <InputText id="confirmPassword" name="confirmPassword" type="password" placeholder="Confirm Password" variant="full" value={formState.confirmPassword} onChange={handleConfirmPasswordOnChange} />
                </div>
                <div className='mb-4'>
                    <Button id="submit" type="submit" extraClass="primary slim full mt-5" disabled={!isValid || !newPasswordMatch || isLoading}>{isLoading ? 'Loading...' : 'Reset Password'}</Button>
                </div>
            </form>
        </Fragment>
    )
}

export default ResetPassword
