import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Alert } from 'reactstrap';
import useApi from '../../hooks/useApi'
import queryString from 'query-string'

const AuthError = ({ location }) => {

    const { errorId } = queryString.parse(location.search)

    const [{ isLoading, error, response }] = useApi({
        method: 'get',
        url: `api/Authenticate/Error?errorId=${errorId}`
    });

    return (
        <Fragment>
            <h1>Authentication Error.</h1>
            <p className="text-muted">There was a problem processing your request</p>
            {response && response.requestId && <small className="text-muted">Request Id: {response.requestId}</small>}
            {isLoading && <small className="text-muted">Loading...</small>}
            {error && <Alert color="danger">There was a problem retrieving the error details {error}</Alert>}
            <p className="mt-5">Return to <Link to="/">Sign In</Link> page</p>
        </Fragment>
    )
}

export default AuthError

