import React from 'react';
import PropTypes from 'prop-types';
import Col from 'gdc-react-components-layouts-col';
import Row from 'gdc-react-components-layouts-row';
import leftlogo from './solera-audatex-logo-black.png';
import './styles.css';

const AuthContainer = ({ children }) => (
  <main className="container-fluid vh-100">
    <Row extraClass="h-100">
      <Col sm={12} md={5} lg={4}>
        <div className="d-flex flex-column justify-content-between p-5 h-100">
          <div className="d-block mx-auto">
            <img className="my-3 logo" src={leftlogo} alt="Solera Audatex" />
          </div>

          <div className="d-flex justify-content-center">
            <div className="form-container">{children}</div>
          </div>

          <div className="py-3 d-flex justify-content-center">
            <div className="form-container">
              <p className="text-secondary">
                <small>
                  © {new Date().getFullYear()} AUDATEX (UK) LIMITED, A SOLERA
                  COMPANY
                </small>
              </p>
              <p className="text-secondary">
                <small>
                  Registered Address: Audatex UK Limited, Solera House, Western
                  Road, Bracknell, Berkshire, RG12 1RF
                </small>
              </p>
              <p className="text-secondary mt-5">
                <small>
                  <a
                    href="https://audatex.co.uk/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PRIVACY POLICY
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </Col>
      <Col
        sm={12}
        md={7}
        lg={8}
        extraClass="splash-image d-none d-md-block"
      ></Col>
    </Row>
  </main>
);

AuthContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContainer;
