import React, { Component } from 'react';
import { Switch, Route } from 'react-router'
import Home from './components/Home';
import Login from './components/Login';
import Logout from './components/Logout';
import ForgottenPassword from './components/ForgottenPassword';
import AuthContainer from './components/AuthContainer';
import NotFound from './components/NotFound';
import AuthError from './components/AuthError';
import ChangePassword from './components/ChangePassword';
import Consent from './components/Consent';
import ResetPassword from './components/ResetPassword';
import SetPassword from './components/SetPassword';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AuthContainer>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/account/login" component={Login} />
                    <Route exact path="/account/logout" component={Logout} />
                    <Route exact path="/forgotpassword" component={ForgottenPassword} />
                    <Route exact path="/changepassword" component={ChangePassword} />
                    <Route exact path="/consent" component={Consent} />
                    <Route exact path="/resetpassword" component={ResetPassword} />
                    <Route exact path="/setpassword" component={SetPassword} />
                    <Route exact path="/home/error" component={AuthError} /> 
                    <Route component={NotFound} />
                </Switch>
            </AuthContainer>
        );
    }
}