import React from 'react';
import LabelledCheckbox from './../LabelledCheckbox';

const capitalise = (input) => input.charAt(0).toUpperCase() + input.slice(1)

const ScopeItem = ({ section, name, displayName, required, checked, onChange }) => {
    const caption = capitalise(displayName || name)
    return (
        <LabelledCheckbox name={name} checked={checked} disabled={required} onChange={() => onChange(name, section)}>
            {caption} {required && "*"}
        </LabelledCheckbox>
    )
}
export default ScopeItem