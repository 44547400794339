import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

const NotFound = ({  }) => {
    return (
        <Fragment>
            <h1 className='mb-5'>404 - Page Not Found.</h1>
            <p>The page you requested could not be found.</p>
            <p>Return to <Link to="/">Sign In</Link> page</p>
        </Fragment>
    )
}

export default NotFound
