import { useState, useEffect } from 'react';
import axios from 'axios';

const useApi = (initialPayload) => {

    const [response, setResponse] = useState(null);
    const [payload, setPayload] = useState(initialPayload);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const callApi = () => {
            const axiosPayload = {
                ...payload,
                cancelToken: source.token,
                credentials: "include",
                headers: {
                    'Content-type': 'application/json'
                }
            };
            setError(null);
            setResponse(null);
            axios(axiosPayload)
                .then(result => {
                    setResponse(result.data);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.info("cancelled");
                    } else {
                        if (error && error.response && error.response.data)
                            setError(error.response.data);
                        else
                            setError(error.message);
                    }
                });

            setIsLoading(false);
        };

        if (payload)
            callApi();

        return () => { // Clean up resources after unmount
            source.cancel();
        };
    }, [payload]);


    return [{ response, isLoading, error }, setPayload];
}

export default useApi
