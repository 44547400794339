import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './styles/site.css'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const isIE = /*@cc_on!@*/false || !!document.documentMode;

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement);

if (!isIE) {
    serviceWorker.unregister();
}