import React from 'react';
import Checkbox from 'gdc-react-components-atoms-checkbox';

const LabelledCheckbox = ({ name, checked, disabled, children, onChange }) =>
    <div>
        <Checkbox id={name} name={name} checked={checked} disabled={disabled} onChange={onChange} />
        <label className="form-check-label text-muted ml-3" htmlFor={name}>
            {children}
        </label>
    </div>

export default LabelledCheckbox