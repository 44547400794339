import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom'
import useApi from './../../hooks/useApi'
import AuthHeader from '../AuthHeader';
import Form from './Form';
import queryString from 'query-string'
import './styles.css';

const Consent = ({ location }) => {

    const query = queryString.parse(location.search)

    const [formState, setFormState] = useState({ returnUrl: query.returnUrl || '' });

    const [{ isLoading, error, response }] = useApi({
        method: 'post',
        url: 'api/Consent/Consent',
        data: formState
    });

    return (
        <Fragment>
            {isLoading && <p>Loading....</p>}
            {response &&
                <Fragment>
                    <AuthHeader
                        title="Consent"
                        error={error} />
                    <p className="mb-0">{response && response.clientName} is requesting your permission</p>
                    <p><small>Uncheck the permissions you do not wish to grant</small></p>
                    <Form {...response} />
                </Fragment>
            }
        </Fragment>
    )
}

export default withRouter(Consent)