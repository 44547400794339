import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { Alert } from 'reactstrap';

const AuthHeader = ({ title, subTitle, error, successMessage }) =>
    <Fragment>
        <h1>{title}</h1>
        {subTitle && <p className="text-muted">{subTitle}</p>}
        {successMessage && <Alert color="success">{successMessage}</Alert>}
        {error && <Alert color="danger">{error}</Alert>}
    </Fragment>

AuthHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    error: PropTypes.string,
    successMessage: PropTypes.string
}

export default AuthHeader

