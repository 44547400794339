import React, { Fragment, useState } from 'react';
import { Alert } from 'reactstrap';
import InputText from 'gdc-react-components-atoms-input-text';
import Button from 'gdc-react-components-atoms-button';
import useApi from './../../hooks/useApi'
import queryString from 'query-string'

const ChangePassword = ({ location }) => {

    const query = queryString.parse(location.search)

    const [formState, setFormState] = useState({ currentPassword: '', newPassword: '', confirmNewPassword: '', id: query.id || '', returnUrl: query.returnUrl || "/" });
    const [{ isLoading, error, response }, callApi] = useApi();

    const handleSubmit = (event) => {
        event.preventDefault();
        callApi({
            method: 'post',
            url: 'api/Authenticate/ChangePassword',
            data: formState
        });
    }

    const handleCurrentPasswordOnChange = value => {
        setFormState({ ...formState, currentPassword: value })
    }

    const handleNewPasswordOnChange = value => {
        setFormState({ ...formState, newPassword: value })
    }

    const handleConfirmPasswordOnChange = value => {
        setFormState({ ...formState, confirmNewPassword: value })
    }

    const isValid = formState.currentPassword && formState.newPassword && formState.confirmNewPassword
    const newPasswordMatch = formState.newPassword === formState.confirmNewPassword

    if (response && response.redirectUrl) {
        window.location = response.redirectUrl;
    }

    return (
        <Fragment>
            {false && <pre>{JSON.stringify(formState, null, 2)}</pre>}
            <h1 className='mb-5'>Change Password.</h1>
            {response && response.isOk && <Alert color="success">Change Password Successful</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
            {!newPasswordMatch && <Alert color="danger">New password and confirm password do not match</Alert>}
            <form onSubmit={handleSubmit}>
                <input id="Id" name="Id" value={formState.Id} type="hidden" />
                <div className="form-group">
                    <InputText id="currentPassword" name="currentPassword" type='password' placeholder="Current Password" variant="full" value={formState.currentPassword} onChange={handleCurrentPasswordOnChange} />
                </div>
                <div className="form-group">
                    <InputText id="newPassword" name="newPassword" type="password" placeholder="New Password" variant="full" value={formState.newPassword} onChange={handleNewPasswordOnChange} />
                </div>
                <div className="form-group">
                    <InputText id="confirmPassword" name="confirmPassword" type="password" placeholder="Confirm Password" variant="full" value={formState.confirmNewPassword} onChange={handleConfirmPasswordOnChange} />
                </div>
                <div className='mb-4'>
                    <Button id="submit" type="submit" extraClass="primary slim full mt-5" disabled={!isValid || !newPasswordMatch || isLoading}>{isLoading ? 'Loading...' : 'Change Password'}</Button>
                </div>
            </form>
        </Fragment>
    )
}

export default ChangePassword
